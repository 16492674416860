













































import { Component, Vue } from "vue-property-decorator";
import ButtonTypes from "./ManualButtons.vue";
import ManualUsing from "./ManualUsing.vue";
import ManualMain from "./ManualMain.vue";

@Component({
  components: {
    ButtonTypes,
    ManualUsing,
    ManualMain,
  },
})
export default class Manual extends Vue {}
