import Vue from 'vue';
import Vuetify from 'vuetify';
import VueRouter from 'vue-router';

import 'vuetify/dist/vuetify.min.css';

import App from './App.vue';
import Home from './components/Home.vue';
import Manual from './components/Manual.vue';
import Privacy from './components/Privacy.vue';

Vue.config.productionTip = true;

Vue.use(VueRouter);
Vue.use(Vuetify);

const router = new VueRouter({
	routes: [
		{ path: '/', component: Home },
		{ path: '/Manual', component: Manual },
		{ path: '/Privacy', component: Privacy }
	],
	mode: 'history'
});

const vuetify = new Vuetify({
	theme: {
		options: { customProperties: true },
		dark: true,
		themes: {
			dark: {
				background: 'transparent'
			}
		}
	}
});

new Vue({
	router,
	vuetify,
	render: h => h(App)
}).$mount('#app');
